import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { equals } from "ramda";

import {
  Box,
  Button,
  Callout,
  Grouper,
  Intent,
  Label,
  TextInput,
  TextArea,
  Checkbox,
  Text
} from "@blasterjs/core";

import {
  AnnotationClassForm,
  changeStudy,
  createOrUpdateStudyRequest,
  EditableAnnotationClassFields,
  studyFetchRequest,
  StudyForm,
  StudyFormFields,
  studyReset
} from "../slices/studyConfiguration";
import ColorPicker from "../components/ColorPicker";
import Content from "../components/Content";
import {
  AnnotationClassType,
  AnnotationClassWithCount,
  formatIndication,
  formatModality,
  Indication,
  Modality,
  UpdateValueWithMaybeReasonForChange
} from "../models";

import Page, { PageBody, PageHeader, PageHeading, PageTabs } from "../components/Page";

import ReasonForChange from "../components/ReasonForChange";
import { areDifferent, idsOnly } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import {
  faPlus,
  faArrowUp,
  faTrashCan,
  faLockOpen,
  faLock
} from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../hooks";
import { StudyConfigurationState } from "../slices/studyConfiguration";
import StudyConfigurationEditUsers from "./StudyConfigurationEditUsers";
import { studyFetch } from "../slices/studies";

library.add(faPlus);
library.add(faArrowDown);
library.add(faArrowUp);
library.add(faTrashCan);
library.add(faLockOpen);
library.add(faLock);

const NowrapGrouper = styled(Grouper)`
  & > div {
    flex-wrap: nowrap;
    width: auto;
  }
`;

interface ConfigValidations {
  readonly nameRequired: string | null;
  readonly indicationRequired: string | null;
  readonly segmentsRequired: string | null;
  readonly modalityRequired: string | null;
  readonly freehandMessage: string | null;
  readonly freehandErrorsExist: boolean;
  readonly multilineMessage: string | null;
  readonly multilineErrorsExist: boolean;
  readonly pointMessage: string | null;
  readonly pointErrorsExist: boolean;
  readonly hpfMessage: string | null;
  readonly hpfErrorsExist: boolean;
  readonly onHoldReasonRequired: string | null;
  readonly errorsExist: boolean;
}

export const initialAnnotationClassState: AnnotationClassForm<AnnotationClassType> = {
  name: "",
  color: "",
  sortOrder: null,
  type: "FREEFORM",
  enabled: true,
  deleted: false
};

type AnnotationClassFields = keyof Pick<
  StudyFormFields,
  | "hpfAnnotationClasses"
  | "pointAnnotationClasses"
  | "freehandAnnotationClasses"
  | "multilineAnnotationClasses"
>;
type UserFields = keyof Pick<StudyFormFields, "uploaders" | "readers" /*| "readonlyUsers"*/>;
type StudyFields = keyof Omit<StudyFormFields, UserFields | AnnotationClassFields>;

export const convAnno = (a: AnnotationClassForm<AnnotationClassType>) => {
  return {
    id: a.id || "no-id",
    name: a.name || "undef",
    color: a.color,
    sortOrder: a.sortOrder,
    type: a.type,
    enabled: a.enabled,
    deleted: a.deleted
  };
};

export const convAnnoOld = (a: AnnotationClassWithCount<AnnotationClassType>) => {
  return {
    id: a.annotationClass.id || "no-id",
    name: a.annotationClass.name || "undef",
    color: a.annotationClass.color,
    sortOrder: a.annotationClass.sortOrder,
    type: a.annotationClass.type,
    enabled: a.annotationClass.enabled,
    deleted: false
  };
};

const StudyConfiguration = () => {
  const params = useParams();
  const id: string = params.id || "no-id";
  const tab: string = params.tab || "";

  return (
    <>
      {tab == "associate-users" ? (
        <StudyConfigurationEditUsers studyId={id} />
      ) : (
        <StudyConfigurationTab />
      )}
    </>
  );
};

const StudyConfigurationTab = () => {
  const params = useParams();
  const id: string = params.id || "no-id";

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const studyConfiguration = useAppSelector(state => state.studyConfiguration);
  const configValidations = useAppSelector(state => validateConfig(state.studyConfiguration));

  useEffect(() => {
    dispatch(!id || id === "new" ? studyReset() : studyFetchRequest(id));
    return () => {
      dispatch(studyFetch(id));
    };
  }, [id]);

  const initSelectedModality: readonly Modality[] = studyConfiguration.study.data.modality.value;
  const initSelectedIndication: readonly Indication[] =
    studyConfiguration.study.data.indications.value;
  const protocolIds: readonly string[] = studyConfiguration.study.data.protocolIds.value;

  const visitIdsOnly: readonly string[] = studyConfiguration.study.data.visitIds.value;
  const labVisitIds: readonly string[] = studyConfiguration.study.data.labVisitIds.value;
  const visitIds = visitIdsOnly.map((vid, vindex) => {
    const lvi = vindex < labVisitIds.length && labVisitIds[vindex];
    return [vid, lvi || ""];
  });

  const numSegments: number = studyConfiguration.study.data.segments.value || 0;
  const anaSegments: Array<number> = Array.from({ length: numSegments }, (_, index) => index + 1);

  const [newProtocolId, setNewProtocolId] = useState("");
  const [newVisitId, setNewVisitId] = useState("");
  const [newLabVisitId, setNewLabVisitId] = useState("");

  // Keep track of form submit state for the purposes of avoiding flicker while submitting the form.
  // When the initial study loads, we want to obscure the whole form with a spinner, but then when
  // we submit the form, we just want to show a spinner on the submit button.
  const [isSubmitting, setSubmitting] = useState(false);
  const hasErrorMessage = "errorMessage" in studyConfiguration.study;
  useEffect(() => {
    setSubmitting(false);
  }, [hasErrorMessage]);

  const updateStudyField = <
    Key extends keyof StudyForm,
    PartialUpdate extends Partial<StudyForm[Key]>
  >(
    studyKey: Key,
    value: PartialUpdate
  ) => {
    dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [studyKey]: {
          ...studyConfiguration.study.data[studyKey],
          ...value
        }
      })
    );
  };
  const updateStudyFieldWithValue = (key: keyof StudyForm) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updateStudyField(key, { value: e.currentTarget.value });
  const updateStudyFieldWithReasonForChange = (key: keyof StudyForm) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updateStudyField(key, { reasonForChange: e.currentTarget.value });

  const onAddProtocolId = () => {
    const protocolId = newProtocolId.trim();
    setNewProtocolId("");
    updateStudyField("protocolIds", {
      value: [...protocolIds, protocolId]
    });
  };
  const onAddVisitId = () => {
    const visitId = newVisitId.trim();
    const labVisitId = newLabVisitId.trim();
    setNewVisitId("");
    setNewLabVisitId("");
    dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        visitIds: { value: [...studyConfiguration.study.data["visitIds"].value, visitId] },
        labVisitIds: { value: [...studyConfiguration.study.data["labVisitIds"].value, labVisitId] }
      })
    );
  };
  const onOnHoldChange = () => {
    const currentOnHoldValue = studyConfiguration.study.data.onHold.value;
    updateStudyField("onHold", { value: !currentOnHoldValue });
  };
  const onSegmentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberOfSegments = parseInt(e.target.value, 10);
    updateStudyField("segments", {
      value: isNaN(numberOfSegments) ? null : numberOfSegments
    });
  };
  const onAnatomicalSegmentChange = (pos: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const index = pos - 1;
      const newSegment: string = e.target.value || "";
      const oldSegments: readonly string[] =
        studyConfiguration.study.data.anatomicalSegments.value.slice() || [];
      const newSegments: string[] = Array.from({ length: numSegments }, _ => "").map((_, cur) =>
        cur > oldSegments.length ? "" : cur == index ? newSegment : oldSegments[cur] || ""
      );

      updateStudyField("anatomicalSegments", {
        value: newSegments
      });
    };
  };
  const onModalityChange = (modality: Modality) => {
    if (containsModality(modality)) {
      const newModalities: readonly Modality[] =
        initSelectedModality.filter(m => m !== modality) || [];
      updateStudyField("modality", {
        value: newModalities
      });
    } else {
      const newModalities: readonly Modality[] = initSelectedModality.concat(modality);
      const orderedModalities: readonly Modality[] = (Object.values(
        Modality
      ) as Modality[]).filter(mmod => newModalities.includes(mmod));
      updateStudyField("modality", {
        value: orderedModalities
      });
    }
  };
  const containsModality = (modality: Modality) => {
    return initSelectedModality.includes(modality);
  };
  const onIndicationsChange = (indication: Indication) => {
    if (indication === Indication.NonalcoholicSteatohepatitis) {
      if (containsIndication(indication)) {
        updateStudyField("indications", {
          value: []
        });
      } else {
        updateStudyField("indications", {
          value: [indication]
        });
      }
    } else if (
      indication === Indication.CeliacDisease &&
      !containsIndication(Indication.CeliacDisease)
    ) {
      const pointClasses = [
        {
          name: "Enterocyte",
          color: "#FF0000",
          enabled: true
        } as AnnotationClassForm<"POINT">,
        {
          name: "Lymphocyte",
          color: "#00FF00",
          enabled: true
        } as AnnotationClassForm<"POINT">
      ];

      const newPointClasses = pointClasses.filter(pointClass => {
        return (
          studyConfiguration.study.data.pointAnnotationClasses.value.find(
            x => x.name === pointClass.name
          ) === undefined
        );
      });

      const multilineClasses = [
        {
          name: "Unit 1",
          color: "#87FF01",
          enabled: true
        } as AnnotationClassForm<"MULTILINE">,
        {
          name: "Unit 2",
          color: "#FF001E",
          enabled: true
        } as AnnotationClassForm<"MULTILINE">,
        {
          name: "Unit 3",
          color: "#FF9F00",
          enabled: true
        } as AnnotationClassForm<"MULTILINE">,
        {
          name: "Unit 4",
          color: "#FDEB03",
          enabled: true
        } as AnnotationClassForm<"MULTILINE">,
        {
          name: "Unit 5",
          color: "#013BFA",
          enabled: true
        } as AnnotationClassForm<"MULTILINE">
      ];

      const newMultilineClasses = multilineClasses.filter(multilineClass => {
        return (
          studyConfiguration.study.data.multilineAnnotationClasses.value.find(
            x => x.name === multilineClass.name
          ) === undefined
        );
      });

      dispatch(
        changeStudy({
          ...studyConfiguration.study.data,
          ["indications"]: {
            value: initSelectedIndication.concat(Indication.CeliacDisease)
          },
          ["multilineAnnotationClasses"]: {
            value: studyConfiguration.study.data["multilineAnnotationClasses"].value.concat(
              newMultilineClasses
            )
          },
          ["pointAnnotationClasses"]: {
            value: studyConfiguration.study.data["pointAnnotationClasses"].value.concat(
              newPointClasses
            )
          }
        })
      );
    } else {
      if (containsIndication(indication)) {
        const newIndications: readonly Indication[] =
          initSelectedIndication.filter(m => m !== indication) || [];
        updateStudyField("indications", {
          value: newIndications
        });
      } else {
        const newIndications: readonly Indication[] = initSelectedIndication.concat(indication);
        const orderedIndications: readonly Indication[] = (Object.values(
          Indication
        ) as Indication[]).filter(mmod => newIndications.includes(mmod));
        updateStudyField("indications", {
          value: orderedIndications
        });
      }
    }
  };

  const containsIndication = (indication: Indication) => {
    return initSelectedIndication.includes(indication);
  };
  const indicationDisabled = (indication: Indication) => {
    switch (indication) {
      case Indication.NonalcoholicSteatohepatitis:
        return (
          initSelectedIndication.length > 0 &&
          !initSelectedIndication.includes(Indication.NonalcoholicSteatohepatitis)
        );
      case Indication.CeliacDisease:
        return (
          initSelectedIndication.length > 0 &&
          !initSelectedIndication.includes(Indication.CeliacDisease)
        );
      default:
        return (
          initSelectedIndication.includes(Indication.NonalcoholicSteatohepatitis) ||
          initSelectedIndication.includes(Indication.CeliacDisease)
        );
    }
  };
  const onAnnotationClassFieldChange = <K extends AnnotationClassFields>(
    annotationClassFormKey: K
  ) => (index: number, field: EditableAnnotationClassFields, value: string) => {
    dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: [
            ...studyConfiguration.study.data[annotationClassFormKey].value.slice(0, index),
            {
              // Mix in any existing values
              ...studyConfiguration.study.data[annotationClassFormKey].value[index],
              // Update specific field with value
              [field]: value
            },
            ...studyConfiguration.study.data[annotationClassFormKey].value.slice(index + 1)
          ]
        }
      })
    );
  };
  const onHpfAnnotationClassFieldChange = onAnnotationClassFieldChange("hpfAnnotationClasses");
  const onPointAnnotationClassFieldChange = onAnnotationClassFieldChange("pointAnnotationClasses");
  const onFreehandAnnotationClassFieldChange = onAnnotationClassFieldChange(
    "freehandAnnotationClasses"
  );
  const onMultilineAnnotationClassFieldChange = onAnnotationClassFieldChange(
    "multilineAnnotationClasses"
  );
  const onAddAnnotationClass = (annotationClassFormKey: AnnotationClassFields) => () => {
    dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: [
            ...studyConfiguration.study.data[annotationClassFormKey].value,
            initialAnnotationClassState
          ]
        }
      })
    );
  };
  const onMoveAnnotationClassUp = (
    annotationClassFormKey: AnnotationClassFields,
    index: number
  ) => () => {
    const oldValues = studyConfiguration.study.data[annotationClassFormKey].value;
    const newValues = [
      ...(index > 1 ? oldValues.slice(0, index - 1) : []),
      oldValues[index],
      oldValues[index - 1],
      ...(index < oldValues.length ? oldValues.slice(index + 1, oldValues.length) : [])
    ];
    dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: newValues
        }
      })
    );
  };
  const onMoveAnnotationClassDown = (
    annotationClassFormKey: AnnotationClassFields,
    index: number
  ) => () => {
    const oldValues = studyConfiguration.study.data[annotationClassFormKey].value;
    const newValues = [
      ...(index > 0 ? oldValues.slice(0, index) : []),
      oldValues[index + 1],
      oldValues[index],
      ...(index < oldValues.length - 1 ? oldValues.slice(index + 2, oldValues.length) : [])
    ];
    dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: newValues
        }
      })
    );
  };

  const onNewProtocolIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const protocolId = e.currentTarget.value;
    setNewProtocolId(protocolId);
  };

  const onNewVisitIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const visitId = e.currentTarget.value;
    setNewVisitId(visitId);
  };

  const onNewLabVisitIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const labVisitId = e.currentTarget.value;
    setNewLabVisitId(labVisitId);
  };

  const onDeleteProtocolId = (index: number) => () => {
    const oldValues: readonly string[] = studyConfiguration.study.data.protocolIds.value;
    const newValues = [
      ...(index > 0 ? oldValues.slice(0, index) : []),
      ...(index < oldValues.length ? oldValues.slice(index + 1, oldValues.length) : [])
    ];
    dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        ["protocolIds"]: {
          ...studyConfiguration.study.data["protocolIds"],
          value: newValues
        }
      })
    );
  };

  const onDeleteVisitId = (index: number) => () => {
    const oldValues = studyConfiguration.study.data.visitIds.value;
    const newValues = [
      ...(index > 0 ? oldValues.slice(0, index) : []),
      ...(index < oldValues.length ? oldValues.slice(index + 1, oldValues.length) : [])
    ];
    dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        ["visitIds"]: {
          ...studyConfiguration.study.data["visitIds"],
          value: newValues
        }
      })
    );
  };

  const onDeleteAnnotationClass = (
    annotationClassFormKey: AnnotationClassFields,
    index: number
  ) => (e: React.MouseEvent) => {
    e.preventDefault();
    const oldValues = studyConfiguration.study.data[annotationClassFormKey].value;
    const newValues = oldValues[index]?.id
      ? [
          ...(index > 0 ? oldValues.slice(0, index) : []),
          { ...oldValues[index], deleted: true },
          ...(index < oldValues.length ? oldValues.slice(index + 1, oldValues.length) : [])
        ]
      : [
          ...(index > 0 ? oldValues.slice(0, index) : []),
          ...(index < oldValues.length ? oldValues.slice(index + 1, oldValues.length) : [])
        ];
    dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: newValues
        }
      })
    );
  };

  const onDisableAnnotationClass = (
    annotationClassFormKey: AnnotationClassFields,
    index: number
  ) => (e: React.MouseEvent) => {
    e.preventDefault();
    const oldValues = studyConfiguration.study.data[annotationClassFormKey].value;
    const newValues = [
      ...(index > 0 ? oldValues.slice(0, index) : []),
      { ...oldValues[index], enabled: false },
      ...(index < oldValues.length ? oldValues.slice(index + 1, oldValues.length) : [])
    ];
    dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: newValues
        }
      })
    );
  };

  const onEnableAnnotationClass = (
    annotationClassFormKey: AnnotationClassFields,
    index: number
  ) => (e: React.MouseEvent) => {
    e.preventDefault();
    const oldValues = studyConfiguration.study.data[annotationClassFormKey].value;
    const newValues = [
      ...(index > 0 ? oldValues.slice(0, index) : []),
      { ...oldValues[index], enabled: true },
      ...(index < oldValues.length ? oldValues.slice(index + 1, oldValues.length) : [])
    ];
    dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: newValues
        }
      })
    );
  };

  const onAddHpfAnnotationClass = onAddAnnotationClass("hpfAnnotationClasses");
  const onAddPointAnnotationClass = onAddAnnotationClass("pointAnnotationClasses");
  const onAddFreehandAnnotationClass = onAddAnnotationClass("freehandAnnotationClasses");
  const onAddMultilineAnnotationClass = onAddAnnotationClass("multilineAnnotationClasses");
  const saveOnClick = () => {
    setSubmitting(true);
    dispatch(createOrUpdateStudyRequest())
      .unwrap()
      .then(study => navigate(`/studies/${study.id}`));
  };
  const cancelOnClick = () => {
    setSubmitting(false);
    navigate(`/studies`);
  };
  const noop = () => {
    return null;
  };
  const error =
    "errorMessage" in studyConfiguration.study ? (
      <Box>
        <Callout title="Error" intent={Intent.DANGER}>
          {studyConfiguration.study.errorMessage}
        </Callout>
      </Box>
    ) : null;
  const reasonForChangeBox = (
    field: keyof StudyForm,
    update: UpdateValueWithMaybeReasonForChange<any>
  ) => (
    <Box key={"rfcb_" + field.toString()}>
      <ReasonForChange
        rkey={"rfc_" + field.toString()}
        mb={2}
        value={"reasonForChange" in update ? update.reasonForChange : ""}
        onChange={updateStudyFieldWithReasonForChange(field)}
      />
      <Box
        className="validationMessage"
        style={{
          display:
            ("reasonForChange" in update ? update.reasonForChange || "" : "").length > 0
              ? "none"
              : "block"
        }}
      >
        Reason for change is required
      </Box>
    </Box>
  );
  const reasonForChangeStudyField = (field: StudyFields) => {
    const update = studyConfiguration.study.data[field];
    return studyConfiguration.savedStudy &&
      !equals(studyConfiguration.savedStudy.study[field], update.value)
      ? reasonForChangeBox(field, update)
      : null;
  };
  const noChangeStudyField = (field: StudyFields) => {
    const update = studyConfiguration.study.data[field];
    if (studyConfiguration.savedStudy) {
      const isDiff = studyConfiguration.savedStudy
        ? !equals(studyConfiguration.savedStudy.study[field], update.value)
        : update.value !== null;
      const rfc: string = (update as any).reasonForChange || "";
      return !isDiff || rfc.length == 0;
    } else {
      return false;
    }
  };
  const reasonForChangeStudyAnnotationField = (field: AnnotationClassFields) => {
    const update = studyConfiguration.study.data[field];
    const newVal = update.value.map(convAnno);
    const oldVal =
      studyConfiguration.savedStudy && studyConfiguration.savedStudy[field].map(convAnnoOld);
    const diff = oldVal && !equals(oldVal, newVal);
    return diff ? reasonForChangeBox(field, update) : null;
  };
  const noChangeStudyAnnotationField = (field: AnnotationClassFields) => {
    const update = studyConfiguration.study.data[field];
    const newVal = update.value.map(convAnno);
    const oldVal =
      studyConfiguration.savedStudy && studyConfiguration.savedStudy[field].map(convAnnoOld);
    const diff = oldVal && !equals(oldVal, newVal);
    const rfc: string = (update as any).reasonForChange || "";
    return !(diff && rfc.length > 0);
  };
  const noChangeStudyUsersField = (field: UserFields) => {
    const update = studyConfiguration.study.data[field];
    const diff =
      studyConfiguration.savedStudy &&
      areDifferent(idsOnly(studyConfiguration.savedStudy[field]), idsOnly(update.value));
    const rfc: string = (update as any).reasonForChange || "";
    return !(diff && rfc.length > 0);
  };

  function nothingIsDirty(): boolean {
    return (
      noChangeStudyField("name") &&
      noChangeStudyField("sponsor") &&
      noChangeStudyField("indications") &&
      noChangeStudyField("protocolIds") &&
      noChangeStudyField("segments") &&
      noChangeStudyField("anatomicalSegments") &&
      noChangeStudyField("visitIds") &&
      noChangeStudyField("modality") &&
      noChangeStudyField("onHold") &&
      noChangeStudyField("onHoldReason") &&
      noChangeStudyAnnotationField("hpfAnnotationClasses") &&
      noChangeStudyAnnotationField("pointAnnotationClasses") &&
      noChangeStudyAnnotationField("freehandAnnotationClasses") &&
      noChangeStudyAnnotationField("multilineAnnotationClasses") &&
      noChangeStudyUsersField("uploaders") &&
      noChangeStudyUsersField("readers")
      //noChangeStudyUsersField("readonlyUsers")
    );
  }

  const visitIdPairs = visitIds.map(va => `${va[0]}-:-${va[1]}`);
  function visitIdPairExists() {
    const newPair = `${newVisitId}-:-${newLabVisitId}`;
    return (
      visitIdPairs.includes(newPair) ||
      (newLabVisitId.length > 0 && labVisitIds.includes(newLabVisitId))
    );
  }

  return (
    <Page>
      {studyConfiguration ? (
        <Box style={{ padding: "0 2rem 4rem" }}>
          <PageHeader>
            <PageHeading>{!id || id === "new" ? "Create Study" : "Edit Study"}</PageHeading>
          </PageHeader>
          <PageTabs
            links={[
              {
                to: `/studies/${id}/configure/settings`,
                label: "Settings"
              },
              ...(id !== "new"
                ? [
                    {
                      to: `/studies/${id}/configure/associate-users`,
                      label: "Users"
                    }
                  ]
                : [])
            ]}
          />
          <Content
            isLoading={
              !isSubmitting &&
              "isPending" in studyConfiguration.study &&
              studyConfiguration.study.isPending
            }
          >
            <PageBody>
              {error}
              <Box>
                <Label>
                  Study Name<sup>*</sup>
                  <TextInput
                    style={{
                      border: "thin solid lightgray",
                      borderRadius: "4px",
                      marginTop: "10px"
                    }}
                    placeholder="Study Name or ID"
                    mb={2}
                    value={studyConfiguration.study.data.name.value || ""}
                    onChange={updateStudyFieldWithValue("name")}
                  />
                  <Box
                    className="validationMessage"
                    style={{
                      display: configValidations.errorsExist ? "block" : "none"
                    }}
                  >
                    {configValidations.nameRequired}
                  </Box>
                </Label>
              </Box>
              {reasonForChangeStudyField("name")}
              <Box>
                <Label>
                  Sponsor Name
                  <TextInput
                    style={{
                      border: "thin solid lightgray",
                      borderRadius: "4px",
                      marginTop: "10px"
                    }}
                    placeholder="Sponsor Name"
                    mb={2}
                    value={studyConfiguration.study.data.sponsor.value || ""}
                    onChange={updateStudyFieldWithValue("sponsor")}
                  />
                </Label>
                {reasonForChangeStudyField("sponsor")}
              </Box>
              <Box className={"configSection"}>
                <Label>
                  Protocol ID(s)
                  <br />
                  <Box style={{ display: "block" }}>
                    {protocolIds.flatMap((protocolId, index) => (
                      <Box
                        key={"prow_" + index.toString()}
                        style={{
                          height: "38px",
                          paddingLeft: "0px",
                          paddingBottom: "5px",
                          display: "flex",
                          marginBottom: "6px"
                        }}
                      >
                        <Box
                          key={"pbox_" + index.toString()}
                          style={{
                            border: "thin solid lightgray",
                            borderRadius: "4px",
                            paddingBottom: "2px",
                            paddingLeft: "10px",
                            marginRight: "10px",
                            width: "80%"
                          }}
                        >
                          {" "}
                          {protocolId}
                        </Box>
                        <Box
                          key={"ptrox_" + index.toString()}
                          style={{
                            marginLeft: "1px",
                            cursor: "grab"
                          }}
                          onClick={onDeleteProtocolId(index)}
                          title="Delete Protocol ID from study"
                        >
                          <FontAwesomeIcon key={"pfa_" + index.toString()} icon={faTrashCan} />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box>{reasonForChangeStudyField("protocolIds")}</Box>
                  <TextInput
                    style={{
                      border: "thin solid lightgray",
                      borderRadius: "4px",
                      marginTop: "10px"
                    }}
                    placeholder="Protocol ID"
                    mb={2}
                    value={newProtocolId}
                    onChange={onNewProtocolIdChange}
                  />
                  <Button
                    key={"ptrash_$index"}
                    onClick={() => onAddProtocolId()}
                    disabled={
                      newProtocolId.trim().length == 0 || protocolIds.includes(newProtocolId.trim())
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;Add Protocol ID
                  </Button>
                </Label>
              </Box>
              <Box className={"configSection"}>
                <Label>
                  <div
                    style={{
                      display: "flex"
                    }}
                  >
                    <Box
                      style={{
                        width: "684px",
                        paddingLeft: "0px",
                        paddingBottom: "5px",
                        marginBottom: "6px",
                        marginRight: "10px"
                      }}
                    >
                      Visit ID(s)
                    </Box>
                    <Box
                      style={{
                        width: "684px",
                        paddingLeft: "0px",
                        paddingBottom: "5px",
                        marginBottom: "6px"
                      }}
                    >
                      Lab Visit ID(s){" "}
                      <span style={{ fontStyle: "italic", fontWeight: "300" }}>
                        optional - Requires a Visit ID in the previous text field
                      </span>
                    </Box>
                  </div>
                  <Box style={{ display: "block" }}>
                    {visitIds.flatMap(([visitId, labVisitId], index) => (
                      <Box
                        key={"vrow_" + index.toString()}
                        style={{
                          height: "40px",
                          background: "lightgray",
                          borderRadius: "4px",
                          display: "flex",
                          paddingTop: "4px",
                          paddingLeft: "0px",
                          paddingBottom: "5px",
                          marginBottom: "6px"
                        }}
                      >
                        <Box
                          key={"vbox_" + index.toString()}
                          style={{
                            width: "684px",
                            border: "thin solid lightgray",
                            background: "white",
                            borderRadius: "4px",
                            marginLeft: "4px",
                            paddingBottom: "2px",
                            paddingLeft: "20px",
                            marginRight: "10px"
                          }}
                        >
                          {" "}
                          {visitId}
                        </Box>
                        <Box
                          key={"vbox_lab_" + index.toString()}
                          style={{
                            width: "684px",
                            background: "white",
                            border: "thin solid lightgray",
                            borderRadius: "4px",
                            paddingBottom: "2px",
                            paddingLeft: "10px",
                            marginRight: "10px"
                          }}
                        >
                          {" "}
                          {labVisitId}
                        </Box>
                        <Box
                          key={"vtrox_" + index.toString()}
                          style={{
                            marginLeft: "1px",
                            cursor: "grab"
                          }}
                          onClick={onDeleteVisitId(index)}
                          title="Delete Visit ID from study"
                        >
                          <FontAwesomeIcon key={"vfa_" + index.toString()} icon={faTrashCan} />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box>{reasonForChangeStudyField("visitIds")}</Box>
                  <div
                    style={{
                      display: "flex"
                    }}
                  >
                    <TextInput
                      style={{
                        width: "684px",
                        border: "thin solid lightgray",
                        borderRadius: "4px",
                        marginTop: "0px",
                        marginRight: "10px"
                      }}
                      placeholder="Visit ID"
                      mb={2}
                      value={newVisitId}
                      onChange={onNewVisitIdChange}
                    />
                    <TextInput
                      style={{
                        width: "684px",
                        border: "thin solid lightgray",
                        borderRadius: "4px",
                        marginTop: "0px"
                      }}
                      placeholder="Lab Visit ID"
                      mb={2}
                      value={newLabVisitId}
                      onChange={onNewLabVisitIdChange}
                    />
                  </div>
                </Label>
                <Box
                  className="validationMessage"
                  style={{
                    display: visitIdPairExists() ? "block" : "none"
                  }}
                >
                  The Visit ID / Lab Visit ID pair must be unique
                </Box>
                <Button
                  onClick={() => onAddVisitId()}
                  disabled={newVisitId.trim().length == 0 || visitIdPairExists()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;Add Visit ID
                </Button>
              </Box>
              <NowrapGrouper mb={2}>
                <Box width="66.666%">
                  <Box mb={2}>
                    <Label>
                      Indications<sup>*</sup>
                      <br />
                      <Box>
                        {Object.values(Indication).map((indication, mindex) => (
                          <Label key={"ind_" + mindex.toString()}>
                            <Box key={"ind_b_" + mindex.toString()}>
                              <Checkbox
                                key={"ind_cb_" + mindex.toString()}
                                placeholder="indication"
                                title={indication.toString()}
                                disabled={indicationDisabled(indication)}
                                checked={containsIndication(indication)}
                                onChange={() => onIndicationsChange(indication)}
                              />
                              <Text
                                className={
                                  indicationDisabled(indication) ? "strike_through" : "default"
                                }
                              >
                                {formatIndication(indication)}
                                {[
                                  Indication.NonalcoholicSteatohepatitis,
                                  Indication.CeliacDisease
                                ].includes(indication) ? (
                                  <span
                                    style={{
                                      fontSize: "smaller",
                                      color: "gray",
                                      fontStyle: "italic"
                                    }}
                                  >
                                    {" "}
                                    -{" "}
                                    {indication === Indication.NonalcoholicSteatohepatitis
                                      ? "NASH"
                                      : "Celiac"}{" "}
                                    Studies only support one indication.
                                  </span>
                                ) : null}
                              </Text>
                              <br />
                            </Box>
                          </Label>
                        ))}
                      </Box>
                    </Label>
                  </Box>
                  <Box
                    className="validationMessage"
                    style={{
                      display: configValidations.errorsExist ? "block" : "none"
                    }}
                  >
                    {configValidations.indicationRequired}
                  </Box>
                  {reasonForChangeStudyField("indications")}
                </Box>
              </NowrapGrouper>
              <Box className={"configSection"} mb={2}>
                <Box mb={1}>
                  <Label>
                    Number of Anatomical Segments
                    <TextInput
                      style={{
                        border: "thin solid lightgray",
                        borderRadius: "4px",
                        marginTop: "10px"
                      }}
                      type="number"
                      min="1"
                      step="1"
                      value={studyConfiguration.study.data.segments.value || ""}
                      onChange={onSegmentsChange}
                    />
                    <Box
                      className="validationMessage"
                      style={{
                        display: configValidations.errorsExist ? "block" : "none"
                      }}
                    >
                      {configValidations.segmentsRequired}
                    </Box>
                  </Label>
                </Box>
                {reasonForChangeStudyField("segments")}
                <Box mb={2}>
                  {anaSegments.map(i => (
                    <Box key={"as_" + i.toString()}>
                      {i.toString() + ". Anatomical Segment"}
                      <br />
                      <TextInput
                        style={{
                          border: "thin solid lightgray",
                          borderRadius: "4px",
                          marginTop: "10px"
                        }}
                        key={"ast_" + i.toString()}
                        value={studyConfiguration.study.data.anatomicalSegments.value[i - 1] || ""}
                        onChange={onAnatomicalSegmentChange(i)}
                      />
                    </Box>
                  ))}
                </Box>
                {reasonForChangeStudyField("anatomicalSegments")}
              </Box>
              <Box mb={2}>
                <Label>
                  Modality<sup>*</sup>
                  <br />
                  <Box>
                    {Object.values(Modality).map((modality, mindex) => (
                      <Label key={"mod_" + mindex.toString()}>
                        <Box key={"b_" + mindex.toString()}>
                          <Checkbox
                            key={"cb_" + mindex.toString()}
                            placeholder="Modality"
                            title={modality.toString()}
                            checked={containsModality(modality)}
                            onChange={() => onModalityChange(modality)}
                          />
                          <Text>{formatModality(modality)}</Text>
                          <br />
                        </Box>
                      </Label>
                    ))}
                  </Box>
                </Label>
              </Box>
              <Box
                className="validationMessage"
                style={{
                  display: configValidations.errorsExist ? "block" : "none"
                }}
              >
                {configValidations.modalityRequired}
              </Box>
              {reasonForChangeStudyField("modality")}
              <Box mb={2}>
                <hr />
              </Box>
              <Box display="flex" mb={1}>
                <Label>
                  HPF Annotation Classes
                  <Box
                    style={{
                      display: configValidations.hpfErrorsExist ? "block" : "none",
                      color: "red"
                    }}
                  >
                    {configValidations.hpfMessage}
                  </Box>
                  <Box>
                    {studyConfiguration.study.data.hpfAnnotationClasses.value.map(
                      (hpfAnnotationClass, index) => (
                        <Box display="flex" key={index} style={{ marginBottom: "4px" }}>
                          <ColorPicker
                            rkey={"cp_${index.toString()}"}
                            color={hpfAnnotationClass.color}
                            onChange={(hexCode: string) => {
                              onHpfAnnotationClassFieldChange(index, "color", hexCode);
                            }}
                          />
                          <Box mb={1} className="configTextInput">
                            {(hpfAnnotationClass.deleted === undefined ||
                              hpfAnnotationClass.deleted === false) &&
                            hpfAnnotationClass.enabled === true ? (
                              <TextInput
                                style={{
                                  border: "thin solid lightgray",
                                  borderRadius: "4px",
                                  marginTop: "10px"
                                }}
                                placeholder="Name"
                                value={hpfAnnotationClass.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onHpfAnnotationClassFieldChange(
                                    index,
                                    "name",
                                    e.currentTarget.value
                                  );
                                }}
                              />
                            ) : (
                              <Box
                                editable={false}
                                onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
                                className={
                                  (hpfAnnotationClass.deleted === undefined ||
                                    hpfAnnotationClass.deleted === false) &&
                                  hpfAnnotationClass.enabled === true
                                    ? "deletedTextBox"
                                    : "disabledTextBox"
                                }
                              >
                                <Text>{hpfAnnotationClass.name}</Text>
                              </Box>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index <
                            studyConfiguration.study.data.hpfAnnotationClasses.value.length - 1 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onMoveAnnotationClassDown("hpfAnnotationClasses", index)}
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faArrowDown} />
                              </div>
                            ) : (
                              <Box style={{ width: "74px" }}></Box>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index > 0 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onMoveAnnotationClassUp("hpfAnnotationClasses", index)}
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faArrowUp} />
                              </div>
                            ) : (
                              <Box style={{ width: "74px" }}></Box>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {hpfAnnotationClass.count === undefined ||
                            hpfAnnotationClass.count === 0 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={
                                  hpfAnnotationClass.deleted
                                    ? noop
                                    : onDeleteAnnotationClass("hpfAnnotationClasses", index)
                                }
                                title="delete annotation class from study"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faTrashCan} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {hpfAnnotationClass.enabled ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onDisableAnnotationClass("hpfAnnotationClasses", index)}
                                title="remove annotation class from use"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faLockOpen} />
                              </div>
                            ) : (
                              <></>
                            )}
                            {!hpfAnnotationClass.enabled ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onEnableAnnotationClass("hpfAnnotationClasses", index)}
                                title="add annotation class back to use"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faLock} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                      )
                    )}
                    <Box mb={1}></Box>
                    <Button onClick={() => onAddHpfAnnotationClass()}>
                      <FontAwesomeIcon icon={faPlus} />
                      &nbsp;Add Class
                    </Button>
                  </Box>
                </Label>
              </Box>
              {reasonForChangeStudyAnnotationField("hpfAnnotationClasses")}
              <Box mb={2}>
                <hr />
              </Box>
              <Box display="flex" mb={1}>
                <Label>
                  Point Annotation Classes
                  <Box>
                    {studyConfiguration.study.data.pointAnnotationClasses.value.map(
                      (pointAnnotationClass, index) => (
                        <Box display="flex" key={index} style={{ marginBottom: "4px" }}>
                          <ColorPicker
                            rkey={"cp_p_${index.toString()}"}
                            color={pointAnnotationClass.color}
                            onChange={(hexCode: string) => {
                              onPointAnnotationClassFieldChange(index, "color", hexCode);
                            }}
                          />
                          <Box mb={1} className="configTextInput">
                            {(pointAnnotationClass.deleted === undefined ||
                              pointAnnotationClass.deleted === false) &&
                            pointAnnotationClass.enabled === true ? (
                              <TextInput
                                style={{
                                  border: "thin solid lightgray",
                                  borderRadius: "4px",
                                  marginTop: "4px",
                                  marginRight: "15px"
                                }}
                                placeholder="Name"
                                value={pointAnnotationClass.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onPointAnnotationClassFieldChange(
                                    index,
                                    "name",
                                    e.currentTarget.value
                                  );
                                }}
                              />
                            ) : (
                              <Box
                                editable={false}
                                onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
                                className={
                                  (pointAnnotationClass.deleted === undefined ||
                                    pointAnnotationClass.deleted === false) &&
                                  pointAnnotationClass.enabled === true
                                    ? "deletedTextBox"
                                    : "disabledTextBox"
                                }
                              >
                                <Text>{pointAnnotationClass.name}</Text>
                              </Box>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index <
                            studyConfiguration.study.data.pointAnnotationClasses.value.length -
                              1 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onMoveAnnotationClassDown("pointAnnotationClasses", index)}
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faArrowDown} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index > 0 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onMoveAnnotationClassUp("pointAnnotationClasses", index)}
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faArrowUp} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {pointAnnotationClass.count === undefined ||
                            pointAnnotationClass.count === 0 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onDeleteAnnotationClass("pointAnnotationClasses", index)}
                                title="delete annotation class from study"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faTrashCan} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {pointAnnotationClass.enabled ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onDisableAnnotationClass("pointAnnotationClasses", index)}
                                title="remove annotation class from use"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faLockOpen} />
                              </div>
                            ) : (
                              <></>
                            )}
                            {!pointAnnotationClass.enabled ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onEnableAnnotationClass("pointAnnotationClasses", index)}
                                title="add annotation class back to use"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faLock} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                      )
                    )}
                    <Box mb={1}></Box>
                    <Button onClick={() => onAddPointAnnotationClass()}>
                      <FontAwesomeIcon icon={faPlus} />
                      &nbsp;Add Class
                    </Button>
                  </Box>
                  <Box
                    className="validationMessage"
                    style={{
                      display: configValidations.pointErrorsExist ? "block" : "none"
                    }}
                  >
                    {configValidations.pointMessage}
                  </Box>
                </Label>
              </Box>
              {reasonForChangeStudyAnnotationField("pointAnnotationClasses")}
              <Box mb={2}>
                <hr />
              </Box>
              <Box display="flex" mb={1}>
                <Label>
                  Freehand Annotation Classes
                  <Box>
                    {studyConfiguration.study.data.freehandAnnotationClasses.value.map(
                      (annotationClass, index) => (
                        <Box display="flex" key={index} style={{ marginBottom: "4px" }}>
                          <ColorPicker
                            rkey={"cp_f_${index.toString()}"}
                            color={annotationClass.color}
                            onChange={(hexCode: string) => {
                              onFreehandAnnotationClassFieldChange(index, "color", hexCode);
                            }}
                          />
                          <Box mb={1} className="configTextInput">
                            {(annotationClass.deleted === undefined ||
                              annotationClass.deleted === false) &&
                            annotationClass.enabled === true ? (
                              <TextInput
                                style={{
                                  border: "thin solid lightgray",
                                  borderRadius: "4px",
                                  marginTop: "10px"
                                }}
                                placeholder="Name"
                                value={annotationClass.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onFreehandAnnotationClassFieldChange(
                                    index,
                                    "name",
                                    e.currentTarget.value
                                  );
                                }}
                              />
                            ) : (
                              <Box
                                editable={false}
                                onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
                                className={
                                  (annotationClass.deleted === undefined ||
                                    annotationClass.deleted === false) &&
                                  annotationClass.enabled === true
                                    ? "deletedTextBox"
                                    : "disabledTextBox"
                                }
                              >
                                <Text>{annotationClass.name}</Text>
                              </Box>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index <
                            studyConfiguration.study.data.freehandAnnotationClasses.value.length -
                              1 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onMoveAnnotationClassDown(
                                  "freehandAnnotationClasses",
                                  index
                                )}
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faArrowDown} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index > 0 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onMoveAnnotationClassUp(
                                  "freehandAnnotationClasses",
                                  index
                                )}
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faArrowUp} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {annotationClass.count === undefined || annotationClass.count === 0 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onDeleteAnnotationClass(
                                  "freehandAnnotationClasses",
                                  index
                                )}
                                title="delete annotation class from study"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faTrashCan} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {annotationClass.enabled ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onDisableAnnotationClass(
                                  "freehandAnnotationClasses",
                                  index
                                )}
                                title="remove annotation class from use"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faLockOpen} />
                              </div>
                            ) : (
                              <></>
                            )}
                            {!annotationClass.enabled ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onEnableAnnotationClass(
                                  "freehandAnnotationClasses",
                                  index
                                )}
                                title="add annotation class back to use"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faLock} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                      )
                    )}
                    <Box mb={1}></Box>
                    <Button onClick={() => onAddFreehandAnnotationClass()}>
                      <FontAwesomeIcon icon={faPlus} />
                      &nbsp;Add Class
                    </Button>
                  </Box>
                  <Box
                    className="validationMessage"
                    style={{
                      display: configValidations.errorsExist ? "block" : "none"
                    }}
                  >
                    {configValidations.freehandMessage}
                  </Box>
                </Label>
              </Box>
              {reasonForChangeStudyAnnotationField("freehandAnnotationClasses")}
              <Box mb={2}>
                <hr />
              </Box>
              <Box display="flex" mb={1}>
                <Label>
                  Line Measurement Annotation Classes
                  <Box>
                    {studyConfiguration.study.data.multilineAnnotationClasses.value.map(
                      (annotationClass, index) => (
                        <Box display="flex" key={index} style={{ marginBottom: "4px" }}>
                          <ColorPicker
                            rkey={"cp_f_${index.toString()}"}
                            color={annotationClass.color}
                            onChange={(hexCode: string) => {
                              onMultilineAnnotationClassFieldChange(index, "color", hexCode);
                            }}
                          />
                          <Box mb={1} className="configTextInput">
                            {(annotationClass.deleted === undefined ||
                              annotationClass.deleted === false) &&
                            annotationClass.enabled === true ? (
                              <TextInput
                                style={{
                                  border: "thin solid lightgray",
                                  borderRadius: "4px",
                                  marginTop: "10px"
                                }}
                                placeholder="Name"
                                value={annotationClass.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onMultilineAnnotationClassFieldChange(
                                    index,
                                    "name",
                                    e.currentTarget.value
                                  );
                                }}
                              />
                            ) : (
                              <Box
                                editable={false}
                                onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
                                className={
                                  (annotationClass.deleted === undefined ||
                                    annotationClass.deleted === false) &&
                                  annotationClass.enabled === true
                                    ? "deletedTextBox"
                                    : "disabledTextBox"
                                }
                              >
                                <Text>{annotationClass.name}</Text>
                              </Box>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index <
                            studyConfiguration.study.data.multilineAnnotationClasses.value.length -
                              1 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onMoveAnnotationClassDown(
                                  "multilineAnnotationClasses",
                                  index
                                )}
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faArrowDown} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index > 0 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onMoveAnnotationClassUp(
                                  "multilineAnnotationClasses",
                                  index
                                )}
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faArrowUp} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {annotationClass.count === undefined || annotationClass.count === 0 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onDeleteAnnotationClass(
                                  "multilineAnnotationClasses",
                                  index
                                )}
                                title="delete annotation class from study"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faTrashCan} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {annotationClass.enabled ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onDisableAnnotationClass(
                                  "multilineAnnotationClasses",
                                  index
                                )}
                                title="remove annotation class from use"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faLockOpen} />
                              </div>
                            ) : (
                              <></>
                            )}
                            {!annotationClass.enabled ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={onEnableAnnotationClass(
                                  "multilineAnnotationClasses",
                                  index
                                )}
                                title="add annotation class back to use"
                              >
                                <FontAwesomeIcon style={{ color: "#52575C" }} icon={faLock} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                      )
                    )}
                    <Box mb={1}></Box>
                    <Button onClick={() => onAddMultilineAnnotationClass()}>
                      <FontAwesomeIcon icon={faPlus} />
                      &nbsp;Add Class
                    </Button>
                  </Box>
                  <Box
                    className="validationMessage"
                    style={{
                      display: configValidations.errorsExist ? "block" : "none"
                    }}
                  >
                    {configValidations.multilineMessage}
                  </Box>
                </Label>
              </Box>
              {reasonForChangeStudyAnnotationField("multilineAnnotationClasses")}
              <Box mb={2}>
                <hr />
              </Box>
              <Box>
                <Label>
                  <Checkbox
                    placeholder="On Hold"
                    checked={studyConfiguration.study.data.onHold.value || false}
                    onChange={onOnHoldChange}
                  />
                  <Text>On Hold</Text>
                </Label>
              </Box>
              {reasonForChangeStudyField("onHold")}
              {studyConfiguration.study.data.onHold.value || false ? (
                <>
                  <Box>
                    <Label>
                      On Hold Reason
                      <Box
                        className="validationMessage"
                        style={{
                          display: configValidations.errorsExist ? "block" : "none"
                        }}
                      >
                        {configValidations.onHoldReasonRequired}
                      </Box>
                      <TextArea
                        placeholder="On Hold Reason"
                        mb={2}
                        maxLength={178}
                        value={studyConfiguration.study.data.onHoldReason.value || ""}
                        onChange={updateStudyFieldWithValue("onHoldReason")}
                      />
                    </Label>
                  </Box>
                  {reasonForChangeStudyField("onHoldReason")}
                </>
              ) : null}
              <Box
                style={{
                  margin: "1.6rem -1.2rem 0",
                  padding: "1.6rem 1.2rem 0",
                  borderTop: "1px solid rgb(221, 224, 230)"
                }}
              >
                <Box>
                  <Button
                    isLoading={isSubmitting}
                    intent="primary"
                    appearance="prominent"
                    disabled={configValidations.errorsExist || nothingIsDirty()}
                    onClick={saveOnClick}
                  >
                    Save Changes
                  </Button>
                  <Button
                    style={{
                      marginLeft: "15px"
                    }}
                    isLoading={isSubmitting}
                    onClick={cancelOnClick}
                  >
                    Discard Changes
                  </Button>
                </Box>
              </Box>
            </PageBody>
          </Content>
        </Box>
      ) : (
        <Box>
          <Callout intent={Intent.WARNING}>Study not found</Callout>
        </Box>
      )}
    </Page>
  );
};

function validateConfig(studyConfiguration: StudyConfigurationState): ConfigValidations {
  const nameMissing = studyConfiguration.study.data.name.value ? false : true;
  const indicationMissing = studyConfiguration.study.data.indications.value.length === 0;
  const segmentsMissing = studyConfiguration.study.data.segments.value === null;
  const modalityMissing =
    studyConfiguration.study.data.modality.value === null ||
    studyConfiguration.study.data.modality.value.length === 0;
  const fhClasses = studyConfiguration.study.data.freehandAnnotationClasses.value.map(ac => {
    return ac.name || "";
  });
  function isNonUnique(classes: readonly string[]): boolean {
    return classes.length !== Array.from(new Set(classes)).length || classes.includes("");
  }
  const fhNonUnique = isNonUnique(fhClasses);
  const mlClasses = studyConfiguration.study.data.multilineAnnotationClasses.value.map(ac => {
    return ac.name || "";
  });
  const mlNonUnique = isNonUnique(mlClasses);
  const pointClasses = studyConfiguration.study.data.pointAnnotationClasses.value.map(ac => {
    return ac.name || "";
  });
  const hpfClasses = studyConfiguration.study.data.hpfAnnotationClasses.value.map(ac => {
    return ac.name || "";
  });
  const pointNonUnique = isNonUnique(pointClasses);
  const hpfNonUnique = isNonUnique(hpfClasses);
  const onHoldReasonMissing = studyConfiguration.study.data.onHold.value
    ? studyConfiguration.study.data.onHoldReason.value
      ? false
      : true
    : false;
  return {
    nameRequired: nameMissing ? "Name is required" : "",
    indicationRequired: indicationMissing ? "Indication is required" : "",
    segmentsRequired: segmentsMissing ? "Number of Anatomical Segments is required" : "",
    modalityRequired: modalityMissing ? "Modality is required" : "",
    freehandMessage: fhNonUnique ? "name is blank or not unique" : "",
    freehandErrorsExist: fhNonUnique,
    multilineMessage: mlNonUnique ? "name is blank or not unique" : "",
    multilineErrorsExist: mlNonUnique,
    pointMessage: pointNonUnique ? "name is blank or not unique" : "",
    pointErrorsExist: pointNonUnique,
    hpfMessage: hpfNonUnique ? "name is blank or not unique" : "",
    hpfErrorsExist: hpfNonUnique,
    onHoldReasonRequired: onHoldReasonMissing ? "On Hold Reason is required" : "",
    errorsExist:
      nameMissing ||
      indicationMissing ||
      segmentsMissing ||
      modalityMissing ||
      fhNonUnique ||
      mlNonUnique ||
      pointNonUnique ||
      hpfNonUnique ||
      onHoldReasonMissing
  };
}

export default StudyConfiguration;
